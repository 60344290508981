import { AbstractGolfBallTrajectoryManager, MainCameraType, assertNonNull } from '@golf-ar/core';
import { Scene, Vector3 } from '@babylonjs/core';

import { GOLF_BALL_RADIUS, GolfBall } from './golf-ball';
import { BallTrail } from './ball-trail';
import { Golfer } from './golfer';

const MIN_VALUE_Y = -2.5;

/** Golf ball trajectory manager. */
export class GolfBallTrajectoryManager extends AbstractGolfBallTrajectoryManager {
	private hasStroke = false;

	// TODO (Sherstkov): Refactor complex constructor.
	public constructor(
		protected override readonly scene: Scene,
		protected override readonly camera: MainCameraType,
		private readonly golfBall: GolfBall,
		private readonly ballTrail: BallTrail,
		private readonly golfer: Golfer,
		private readonly addStroke: () => void,
		private readonly getIsBallInHole: () => boolean,
	) {
		super(scene, golfBall.instanceMesh, camera, GOLF_BALL_RADIUS);

		this.golfer.changeGolferPosition();

		// Observable for disabling actions when ball is moving.
		this.scene.onAfterPhysicsObservable.add(() => {
			const ballPhysicsBody = this.ballMesh.physicsBody;
			assertNonNull(ballPhysicsBody);
			const velocity = Vector3.Zero();

			ballPhysicsBody.getAngularVelocityToRef(velocity);

			const isStoppedX = this.getAbsoluteVelocityValueFor(velocity.x) === 0;
			const isStoppedY = this.getAbsoluteVelocityValueFor(velocity.y) === 0;
			const isStoppedZ = this.getAbsoluteVelocityValueFor(velocity.z) === 0;

			// Check for action manager is needed in order to avoid multiple register and unregister it.
			assertNonNull(this.ballMesh.actionManager);
			const { hasPickTriggers } = this.ballMesh.actionManager;

			const isStopped = isStoppedX && isStoppedY && isStoppedZ;

			if (isStopped &&
				!hasPickTriggers &&
				this.isEnabled &&
				!this.golfer.instanceAnimation.isPlaying &&
				this.ballMesh.absolutePosition.y >= MIN_VALUE_Y) {

				if (!getIsBallInHole()) {
					this.golfer.changeGolferPosition();
					this.golfer.instanceAnimation.reset();
				}

				this.ballMesh.actionManager.registerAction(this.action);
				this.isBallStopped = true;

				if (this.hasStroke) {
					this.addStroke();
					this.hasStroke = false;
				}
			}

			if (!isStopped && hasPickTriggers && this.golfer.instanceAnimation.isPlaying) {
				this.isBallStopped = false;
			}
		});

	}

	/** @inheritdoc */
	protected override createBallTrajectory(): void {
		if (this.pickedPoint == null) {
			return;
		}
		const impulse = this.getImpulse();
		this.golfBallArrow.createArrowFor(impulse);
		this.golfer.changeLookAt(impulse);
	}

	/** @inheritdoc */
	protected override onMouseUp(): void {
		if (this.pickedPoint == null) {
			return;
		}
		assertNonNull(this.ballMesh.actionManager);
		this.ballMesh.actionManager.unregisterAction(this.action);

		this.golfer.instanceAnimation.play(false);
		this.hideLines();
		this.impactAngleIndicator.hideIndicator();
	}

	/** Starts impulse generation. */
	public startGenerateImpulse(): void {
		this.golfBall.ballPositionBeforeImpact = this.ballMesh.absolutePosition;
		this.scene.onAfterRenderObservable.add(() => {
			if (this.ballMesh.absolutePosition.y < MIN_VALUE_Y) {
				this.ballTrail.dispose();
				this.golfBall.restorePositionAfterFall();
			}
		});

		this.ballTrail.create(this.golfBall.instanceColliderMeshForTrail);

		this.scene.onAfterRenderObservable.addOnce(() => {
			this.generateImpulse();
		});

		this.hasStroke = true;
	}
}
