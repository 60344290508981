import { AbstractMesh, GroundMesh, IDisposable, MeshBuilder, Scene } from '@babylonjs/core';

import { VISIBILITY_OF_AUXILIARY_MESHES } from './scene';
import { GolfBall } from './golf-ball';
import { Field } from './field';

/** Ground. */
export class Ground implements IDisposable {

	/** Instance of the ground. */
	public instance: GroundMesh;

	private readonly resources: IDisposable[] = [];

	public constructor(
		private readonly scene: Scene,
		private readonly golfBall: GolfBall,
		private readonly field: Field,
	) {
		this.instance = this.createGround();
	}

	/** @inheritdoc */
	public dispose(): void {
		this.resources.forEach(resource => resource.dispose());
	}

	/**
	 * Adds the passed meshes as a children to the ground mesh.
	 * @param children Children meshes.
	 */
	public addChildren(children: AbstractMesh[]): void {
		children.forEach(child => {
			child.parent = this.instance;
		});
	}

	/** Enables and disables pre-step that consists in updating physics body including physical bodies of children. */
	public set shouldDisablePhysicsBodyStep(isDisabled: boolean) {
		this.golfBall.shouldDisablePhysicsBodyStep = isDisabled;
		this.field.shouldDisablePhysicsBodyStep = isDisabled;
	}

	private createGround(): GroundMesh {
		const ground = MeshBuilder.CreateGround('ground', { width: 100, height: 100 }, this.scene);
		ground.visibility = VISIBILITY_OF_AUXILIARY_MESHES;
		ground.position.y = 0;

		this.resources.push(ground);

		return ground;
	}
}
