import { AbstractMesh, AnimationEvent, AnimationGroup, IDisposable, Mesh, MeshBuilder, Scene, SceneLoader, Vector3 } from '@babylonjs/core';
import '@babylonjs/loaders';
import { assertNonNull } from '@golf-ar/core';

import { GOLF_BALL_RADIUS, GolfBall } from './golf-ball';
import { GolferAnimations, GolferMesh, GolferType, DriverStick } from './interfaces';
import { Field } from './field';
import { VISIBILITY_OF_AUXILIARY_MESHES } from './scene';

const COEFFICIENT_OF_SCALE_GOLFER = 100;
const SHIFT_GOLFER_DRIVER_X = 0.0027 * COEFFICIENT_OF_SCALE_GOLFER;
const SHIFT_GOLFER_DRIVER_Z = 0.0096 * COEFFICIENT_OF_SCALE_GOLFER;
const SHIFT_GOLFER_PUTTER_X = 0.0027 * COEFFICIENT_OF_SCALE_GOLFER;
const SHIFT_GOLFER_PUTTER_Z = 0.008 * COEFFICIENT_OF_SCALE_GOLFER;

/** Optional yaw (y-axis) correction in radians for parent golfer mesh. */
const CORRECTION_ROTATION_Y_IN_RADIANS = -Math.PI / 2;

/** Golfer. */
export class Golfer implements IDisposable {

	private _instancesAnimations: GolferAnimations | null = null;

	private _instanceGolfersMeshes: GolferMesh | null = null;

	private currentGolfer = GolferType.Driver;

	private _driverSticks: DriverStick | null = null;

	private _instanceParentGolferMesh: Mesh | null = null;

	private readonly resources: IDisposable[] = [];

	public constructor(
		private readonly scene: Scene,
		private readonly golfBall: GolfBall,
		private readonly field: Field,
	) {}

	/** Instance animation current golfer. */
	public get instanceAnimation(): AnimationGroup {
		if (this.currentGolfer === GolferType.Driver) {
			const animation = this.instancesAnimations.swing;
			return animation;
		}

		const animation = this.instancesAnimations.putt;
		return animation;
	}

	/** Instance mesh current golfer. */
	public get instanceMesh(): AbstractMesh {
		if (this.currentGolfer === GolferType.Driver) {
			return this.instanceMeshDriver;
		}
		return this.instanceMeshPutter;
	}

	/** Instance mesh putter golfer. */
	public get instanceMeshPutter(): AbstractMesh {
		const putter = this.instancesMeshes.putter.at(0);
		assertNonNull(putter);
		return putter;
	}

	/** Instance mesh driver golfer. */
	public get instanceMeshDriver(): AbstractMesh {
		const driver = this.instancesMeshes.driver.at(0);
		assertNonNull(driver);
		return driver;
	}

	/** Instance parent golfer mesh. */
	public get instanceParentGolferMesh(): Mesh {
		assertNonNull(this._instanceParentGolferMesh);
		return this._instanceParentGolferMesh;
	}

	/**
	 * Orients the parent golfer mesh towards a target point.
	 * @param differencePosition The value of the position difference between the current and target point.
	 */
	public changeLookAt(differencePosition: Vector3): void {
		const position = this.instanceParentGolferMesh.absolutePosition.add(
			new Vector3(differencePosition.x, 0, differencePosition.z),
		);
		this.instanceParentGolferMesh.lookAt(position, CORRECTION_ROTATION_Y_IN_RADIANS);
	}

	/**
	 * Creates golfer.
	 * @param position Starting position of the golfer.
	 */
	public async create(position: Vector3): Promise<void> {
		this._instanceParentGolferMesh = this.createParentGolferMesh(position);
		const golferDriver = await this.createGolferDriver();
		const golferPutter = await this.createGolferPutter();

		this._instancesAnimations = {
			swing: golferDriver.golferAnimationGroup,
			putt: golferPutter.golferAnimationGroup,
		};
		this._instanceGolfersMeshes = {
			driver: golferDriver.meshes,
			putter: golferPutter.meshes,
		};

		this.instanceMeshDriver.setParent(this.instanceParentGolferMesh);
		this.instanceMeshPutter.setParent(this.instanceParentGolferMesh);
	}

	/**
	 * Creates golfer animations.
	 * @param eventAfterImpact The event triggered after impacting the ball.
	 */
	public createGolferAnimationsEvent(eventAfterImpact: () => void): void {
		const frameOfImpactingBallDriver = 73;
		const frameOfImpactingBallPitter = 82;

		this.createGolferAnimationEvent(this.instancesAnimations.swing.name, frameOfImpactingBallDriver, eventAfterImpact);
		this.createGolferAnimationEvent(this.instancesAnimations.putt.name, frameOfImpactingBallPitter, eventAfterImpact);
	}

	/** Set position of the golfer. */
	public changeGolferPosition(): void {
		this.changeGolfer();

		this.instanceParentGolferMesh.setAbsolutePosition(this.golfBall.instanceMesh.absolutePosition);

		this.instanceParentGolferMesh.lookAt(this.field.getHolePosition(), CORRECTION_ROTATION_Y_IN_RADIANS);
	}

	/** @inheritdoc */
	public dispose(): void {
		this.resources.forEach(resource => resource.dispose());
	}

	private createGolferAnimationEvent(name: string, frameOfImpacting: number, eventAfterImpact: () => void): void {
		const golferAnimation = this.scene.getAnimationGroupByName(name);
		assertNonNull(golferAnimation);

		const animationEvent = this.createAnimationEvent(eventAfterImpact, frameOfImpacting);
		golferAnimation.targetedAnimations[0].animation.addEvent(animationEvent);
	}

	private createParentGolferMesh(position: Vector3): Mesh {
		const mesh = MeshBuilder.CreateSphere('ParentGolferMesh', { diameter: GOLF_BALL_RADIUS * 2, segments: 10 });
		mesh.visibility = VISIBILITY_OF_AUXILIARY_MESHES;
		mesh.setAbsolutePosition(position.clone());
		return mesh;
	}

	private changeGolfer(): void {
		const { min, max } = this.field.instanceMesh.getHierarchyBoundingVectors();
		const distance = Math.abs(max.x - min.x);
		const ballPositionX = this.golfBall.instanceMesh.absolutePosition.x;

		if (ballPositionX <= min.x + (distance / 3)) {
			this.setMeshesVisibility(this.instancesMeshes.putter, false);
			this.setMeshesVisibility(this.instancesMeshes.driver, true);
			this.driverSticks.iron.isVisible = false;
			this.currentGolfer = GolferType.Driver;
		} else if (ballPositionX >= max.x - (distance / 3)) {
			this.setMeshesVisibility(this.instancesMeshes.driver, false);
			this.setMeshesVisibility(this.instancesMeshes.putter, true);
			this.currentGolfer = GolferType.Putter;
		} else {
			this.setMeshesVisibility(this.instancesMeshes.putter, false);
			this.setMeshesVisibility(this.instancesMeshes.driver, true);
			this.driverSticks.driver.isVisible = false;
			this.currentGolfer = GolferType.Driver;
		}
	}

	private get driverSticks(): DriverStick {
		assertNonNull(this._driverSticks);
		return this._driverSticks;
	}

	private async createGolferDriver(): Promise<{
		golferAnimationGroup: AnimationGroup;
		meshes: AbstractMesh[];
	}> {
		const { animationGroups, meshes } = await SceneLoader.ImportMeshAsync(
			'', './assets/models/golfers/', 'Golfer_Drive_2stick.glb', this.scene,
		);
		const golferAnimationGroup = animationGroups.at(0);
		assertNonNull(golferAnimationGroup);
		golferAnimationGroup.stop();

		const golferMesh = meshes.at(0);
		assertNonNull(golferMesh);
		golferMesh.scaling.scaleInPlace(COEFFICIENT_OF_SCALE_GOLFER);
		golferMesh.rotate(Vector3.Up(), 90);

		meshes.forEach(mesh => {
			mesh.isPickable = false;
		});

		const stickDriver = meshes.find(mesh => mesh.name === 'Stick_Driver');
		assertNonNull(stickDriver);
		const stickIron = meshes.find(mesh => mesh.name === 'Stick_Iron');
		assertNonNull(stickIron);
		stickIron.isVisible = false;

		this._driverSticks = {
			driver: stickDriver,
			iron: stickIron,
		};

		const { x, y, z } = this.instanceParentGolferMesh.absolutePosition.clone();

		const positionX = x + SHIFT_GOLFER_DRIVER_X;
		const positionZ = z + SHIFT_GOLFER_DRIVER_Z;

		golferMesh.setAbsolutePosition(new Vector3(positionX, y, positionZ));

		this.resources.push(...animationGroups, ...meshes);

		return { golferAnimationGroup, meshes };
	}

	private async createGolferPutter(): Promise<{
		golferAnimationGroup: AnimationGroup;
		meshes: AbstractMesh[];
	}> {
		const { animationGroups, meshes } = await SceneLoader.ImportMeshAsync(
			'', './assets/models/golfers/', 'Golfer_Putt.glb', this.scene,
		);
		const golferAnimationGroup = animationGroups.at(0);
		assertNonNull(golferAnimationGroup);
		golferAnimationGroup.stop();

		const golferMesh = meshes.at(0);
		assertNonNull(golferMesh);
		golferMesh.scaling.scaleInPlace(COEFFICIENT_OF_SCALE_GOLFER);
		golferMesh.rotate(Vector3.Up(), 90);
		meshes.forEach(mesh => {
			mesh.isVisible = false;
			mesh.isPickable = false;
		});

		const { x, y, z } = this.instanceParentGolferMesh.absolutePosition.clone();

		const positionX = x + SHIFT_GOLFER_PUTTER_X;
		const positionZ = z + SHIFT_GOLFER_PUTTER_Z;

		golferMesh.setAbsolutePosition(new Vector3(positionX, y, positionZ));

		this.resources.push(...animationGroups, ...meshes);

		return { golferAnimationGroup, meshes };
	}

	private setMeshesVisibility(meshes: AbstractMesh[], isVisible: boolean): void {
		meshes.forEach(mesh => {
			mesh.isVisible = isVisible;
		});
	}

	private createAnimationEvent(eventAfterImpact: () => void, frame: number): AnimationEvent {
		const event = new AnimationEvent(
			frame,
			() => eventAfterImpact(),
			true,
		);
		return event;
	}

	private get instancesMeshes(): GolferMesh {
		assertNonNull(this._instanceGolfersMeshes);
		return this._instanceGolfersMeshes;
	}

	private get instancesAnimations(): GolferAnimations {
		assertNonNull(this._instancesAnimations);
		return this._instancesAnimations;
	}
}
