import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { assertNonNull, DeviceOrientationManager } from '@golf-ar/core';

/** Orientation overlay message component. */
@Component({
	selector: 'gaw-orientation-overlay-message',
	templateUrl: './orientation-overlay-message.component.html',
	styleUrls: ['./orientation-overlay-message.component.css'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrientationOverlayMessageComponent implements AfterViewInit {

	/** Orientation overlay element. */
	@ViewChild('orientationOverlay')
	public orientationOverlay: ElementRef<HTMLDivElement> | null = null;

	/** @inheritdoc */
	public ngAfterViewInit(): void {
		assertNonNull(this.orientationOverlay);
		DeviceOrientationManager.registerDeviceOrientationChange(this.orientationOverlay.nativeElement);
	}
}
