import { IPhysicsCollisionEvent, Mesh, Vector3 } from '@babylonjs/core';
import { FieldMeshName, assertNonNull, getValidName } from '@golf-ar/core';

type MeshNameToDamping = FieldMeshName.SandBunker |
FieldMeshName.Field |
FieldMeshName.Green |
FieldMeshName.Tee |
FieldMeshName.Fireway;

type MapSurfaceToDampingType = Readonly<Record<MeshNameToDamping, {

	/** Damping. */
	damping: number;
}>>;

/** The value for linear and angular damping for the ball, which simulate friction on different types of surface. */
// The reason why we can't use the friction itself, see here https://forum.babylonjs.com/t/friction-not-working/490
const MAP_SURFACE_TO_DAMPING: MapSurfaceToDampingType = {
	[FieldMeshName.SandBunker]: { damping: 5 },
	[FieldMeshName.Field]: { damping: 3 },
	[FieldMeshName.Green]: { damping: 1 },
	[FieldMeshName.Tee]: { damping: 1 },
	[FieldMeshName.Fireway]: { damping: 2 },
};

/** Collision manager. */
export class CollisionManager {
	/**
	 * Registers all collisions of the ball with the surface and changes the damping of the ball depending on the type of surface.
	 * @param ball Golf ball.
	 * @param collisionEvent Observable for collision started and collision continued events.
	 * @returns Whether the collision is complete and the ball has collided with the hole.
	 */
	public static isCollisionComplete(
		ball: Mesh,
		collisionEvent: IPhysicsCollisionEvent,
	): boolean {
		const physicalBallBody = ball.physicsBody;
		assertNonNull(physicalBallBody);

		// Check if the physical body of the ball is collides with the surface.
		if (physicalBallBody !== collisionEvent.collider) {
			return false;
		}

		const name = getValidName(collisionEvent.collidedAgainst.transformNode, FieldMeshName.isValid);

		// Check if the collided surface is part of the map with damping values.
		if (this.isKeyMapSurfaceToDamping(name)) {
			const { damping } = MAP_SURFACE_TO_DAMPING[name];
			physicalBallBody.setLinearDamping(damping);
			physicalBallBody.setAngularDamping(damping);
			return false;
		}

		// Check if the ball has collided with the hole and its position is valid.
		if (name === FieldMeshName.Hole && ball.absolutePosition.y <= 0) {
			physicalBallBody.setLinearVelocity(Vector3.Zero());
			return true;
		}

		return false;
	}

	private static isKeyMapSurfaceToDamping(name: FieldMeshName): name is MeshNameToDamping {
		return [...Object.keys(MAP_SURFACE_TO_DAMPING)].includes(name);
	}
}
