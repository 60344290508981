import { AbstractMesh, AnimationGroup } from '@babylonjs/core';

/** Type of golfer. */
export enum GolferType {
	Driver,
	Putter,
}

/** Golfer animations. */
export type GolferAnimations = {

	/** Golfer driver and iron animation. */
	swing: AnimationGroup;

	/** Golfer putter animation. */
	putt: AnimationGroup;
};

/** Golfer. */
export type GolferMesh = {

	/** Driver and Iron. */
	driver: AbstractMesh[];

	/** Putter. */
	putter: AbstractMesh[];
};

/** Types of stick in the driver model. */
export type DriverStick = {

	/** Driver. */
	driver: AbstractMesh;

	/** Iron. */
	iron: AbstractMesh;
};
