import { IDisposable, Scene } from '@babylonjs/core';
import { AdvancedDynamicTexture, Control, ImageBasedSlider, Image, TextBlock } from '@babylonjs/gui';

type DirectionType = 'up' | 'down';

/** Impact angle indicator. */
export class ImpactAngleIndicator implements IDisposable {

	private readonly onChangeIndicatorCallback: () => void;

	private readonly slider: ImageBasedSlider;

	private readonly sliderValueText: TextBlock;

	private readonly advancedTexture: AdvancedDynamicTexture;

	private direction: DirectionType = 'up';

	private readonly resources: IDisposable[] = [];

	public constructor(
		private readonly scene: Scene,
	) {
		this.onChangeIndicatorCallback = this.changeSliderValue.bind(this);
		this.advancedTexture = this.createAdvancedTexture();
		this.slider = this.createImpactAngleIndicator();
		this.sliderValueText = this.createSliderValueText();
	}

	/** @inheritdoc */
	public dispose(): void {
		this.resources.forEach(resource => resource.dispose());
		this.unregisterChangeIndicator();
	}

	private createAdvancedTexture(): AdvancedDynamicTexture {
		const advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI('ImpactAngleIndicator');
		this.resources.push(this.advancedTexture);
		return advancedTexture;
	}

	private createImpactAngleIndicator(): ImageBasedSlider {
		const slider = new ImageBasedSlider();
		slider.backgroundImage = new Image('backgroundSlider', './assets/image-for-gui/slider.png');
		slider.valueBarImage = new Image('backgroundSlider', './assets/image-for-gui/value-slider.png');
		slider.thumbImage = new Image('backgroundSlider', './assets/image-for-gui/thumb-slider.png');

		slider.isVertical = true;
		slider.height = '25%';
		slider.width = '20px';
		slider.left = '-10px';
		slider.thumbWidth = '15px';
		slider.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
		slider.isEnabled = false;
		slider.isVisible = false;

		this.advancedTexture.addControl(slider);

		return slider;
	}

	private createSliderValueText(): TextBlock {
		const textBlock = new TextBlock();
		textBlock.fontSize = 12;
		textBlock.color = 'white';
		textBlock.resizeToFit = true;
		textBlock.left = '-30x';
		textBlock.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
		textBlock.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
		textBlock.isVisible = false;

		this.advancedTexture.addControl(textBlock);

		return textBlock;
	}

	/**
	 * Show the indicator.
	 * @param min Minimum value of the slider.
	 * @param max Maximum value of the slider.
	 */
	public showIndicator(min: number, max: number): void {
		this.changeSliderRange(min, max);
		this.slider.value = min;
		this.slider.isVisible = true;
		this.sliderValueText.isVisible = true;
		this.registerChangeIndicator();
	}

	/** Hide the indicator. */
	public hideIndicator(): void {
		this.slider.isVisible = false;
		this.sliderValueText.isVisible = false;
		this.unregisterChangeIndicator();
	}

	/** Impact angle. */
	public get impactAngle(): number {
		return this.slider.value;
	}

	private changeSliderRange(min: number, max: number): void {
		this.slider.minimum = min;
		this.slider.maximum = max;
	}

	private changeSliderValue(): void {
		const step = 0.15;

		if (this.slider.value === this.slider.minimum) {
			this.direction = 'up';
		} else if (this.slider.value === this.slider.maximum) {
			this.direction = 'down';
		}

		if (this.direction === 'up') {
			this.slider.value += step;
			this.changeSliderValueText();
			return;
		}
		this.slider.value -= step;
		this.changeSliderValueText();
	}

	private changeSliderValueText(): void {
		const sliderIndent = 15;
		this.sliderValueText.top = this.slider.thumbImage.centerY - sliderIndent;
		this.sliderValueText.text = `${Math.round(this.slider.value)} °`;
	}

	private registerChangeIndicator(): void {
		this.scene.registerBeforeRender(this.onChangeIndicatorCallback);
	}

	private unregisterChangeIndicator(): void {
		this.scene.unregisterBeforeRender(this.onChangeIndicatorCallback);
	}
}
