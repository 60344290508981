import { IDisposable } from '@babylonjs/core';
import { Button, AdvancedDynamicTexture, Control } from '@babylonjs/gui';
import { assertNonNull } from '@golf-ar/core';

/** Button text. */
enum ButtonText {
	Confirm = 'Confirm position',
	Change = 'Change position',
}

/** Control scene manager. */
export class SceneControlManager implements IDisposable {
	private isEnabledSceneControl = true;

	private button: Button;

	private readonly resources: IDisposable[] = [];

	public constructor(
		private readonly isBallStopped: boolean,
		private readonly isEnabledFieldDragManager: (value: boolean) => void,
	) {
		this.button = this.createButton();
		this.button.onPointerUpObservable.add(this.registerPointerUp.bind(this));
	}

	/** @inheritdoc */
	public dispose(): void {
		this.resources.forEach(resource => resource.dispose());
	}

	/** Is scene control enabled. */
	public get isEnabled(): boolean {
		return this.isEnabledSceneControl;
	}

	private createButton(): Button {
		const advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI('ImpactAngleIndicator');
		this.resources.push(advancedTexture);

		const button = Button.CreateSimpleButton('ControlSceneButton', ButtonText.Confirm);
		button.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
		button.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
		button.left = '10px';
		button.top = '10px';
		button.background = 'white';
		button.width = '75px';
		button.height = '75px';
		button.color = 'black';
		button.fontSize = 14;

		advancedTexture.addControl(button);

		return button;
	}

	private registerPointerUp(): void {
		if (this.isBallStopped) {
			this.isEnabledSceneControl = !this.isEnabledSceneControl;
			this.isEnabledFieldDragManager(this.isEnabledSceneControl);
			assertNonNull(this.button.textBlock);
			if (this.isEnabledSceneControl) {
				this.button.textBlock.text = ButtonText.Confirm;
			} else {
				this.button.textBlock.text = ButtonText.Change;
			}
		}
	}
}
