import { IDisposable, Vector2 } from '@babylonjs/core';

import { assertNonNull } from '../utils/assert-non-null';

import { MainCamera } from './main-camera';

/** Represents a number of touches on which we want to trigger events. */
export const NUMBER_OF_TOUCHES = 2;

/** Abstract field control manager. */
export abstract class AbstractFieldControlManager implements IDisposable {
	/** The first initial touch. */
	protected firstInitialTouch: Vector2 | null = null;

	/** The second initial touch. */
	protected secondInitialTouch: Vector2 | null = null;

	/** Scale value. */
	protected scaleValue = 0;

	/** Initial distance. */
	protected initialDistance = 0;

	private readonly onTouchstartCallback: (e: TouchEvent) => void;

	private readonly onTouchmoveCallback: (e: TouchEvent) => void;

	private readonly onTouchendCallback: (e: TouchEvent) => void;

	public constructor(
		protected readonly camera: MainCamera,
		protected readonly canvas: HTMLCanvasElement,
	) {
		this.onTouchmoveCallback = this.onTouchmove.bind(this);
		this.onTouchstartCallback = this.onTouchstart.bind(this);
		this.onTouchendCallback = this.onTouchend.bind(this);

		this.registerTouchmoveListener();
		this.registerTouchstartListener();
		this.registerTouchendListener();
	}

	/** @inheritdoc */
	public dispose(): void {
		this.unregisterTouchmoveListener();
		this.unregisterTouchstartListener();
		this.unregisterTouchendListener();
	}

	private registerTouchmoveListener(): void {
		this.canvas.addEventListener('touchmove', this.onTouchmoveCallback, true);
	}

	private unregisterTouchmoveListener(): void {
		this.canvas.removeEventListener('touchmove', this.onTouchmoveCallback);
	}

	private registerTouchstartListener(): void {
		this.canvas.addEventListener('touchstart', this.onTouchstartCallback, true);
	}

	private unregisterTouchstartListener(): void {
		this.canvas.removeEventListener('touchstart', this.onTouchstartCallback);
	}

	private registerTouchendListener(): void {
		this.canvas.addEventListener('touchend', this.onTouchendCallback, true);
	}

	private unregisterTouchendListener(): void {
		this.canvas.removeEventListener('touchend', this.onTouchendCallback);
	}

	protected abstract onTouchstart(event: TouchEvent): void;

	protected abstract onTouchmove(event: TouchEvent): void;

	protected abstract onTouchend(_event: TouchEvent): void;

	/**
	 * Get vectors from the touch list.
	 * @param event Touch event.
	 */
	protected getVectorsFromTouchList(event: TouchEvent): [Vector2, Vector2] {
		const firstTouch = event.touches.item(0);
		const secondTouch = event.touches.item(1);

		// Otherwise there shouldn't be 2 touches in event.
		assertNonNull(firstTouch);
		assertNonNull(secondTouch);

		const firstPoint = new Vector2(firstTouch.clientX, firstTouch.clientY);
		const secondPoint = new Vector2(secondTouch.clientX, secondTouch.clientY);

		return [firstPoint, secondPoint];
	}

	/**
	 * Change the field distance to the camera.
	 * @param distance Distance value at which the camera approaches the field.
	 */
	protected changeFieldDistanceToCamera(distance: number): void {
		this.scaleValue += distance;
		this.camera.cameraPositionByFrontPosition = distance;
	}
}
