import { AbstractFieldControlManager, MainCamera, assertNonNull, calculateDistanceBetween2DPoints } from '@golf-ar/core';

import { Ground } from './ground';
import { SceneControlManager } from './scene-control-manager';

// Represents a number of touches on which we want to trigger events.
const NUMBER_OF_TOUCHES = 2;

/** Field control manager. */
export class FieldControlManager extends AbstractFieldControlManager {
	private initialAngleChange = 0;

	public constructor(
		protected override readonly camera: MainCamera,
		protected override readonly canvas: HTMLCanvasElement,
		private readonly ground: Ground,
		private readonly controlSceneManager: SceneControlManager,
	) {
		super(camera, canvas);
	}

	/**
	 * Handle touch start event.
	 * @param event Touch event.
	 */
	protected override onTouchstart(event: TouchEvent): void {
		if (event.touches.length === NUMBER_OF_TOUCHES) {
			const [firstPoint, secondPoint] = this.getVectorsFromTouchList(event);
			this.firstInitialTouch = firstPoint;
			this.secondInitialTouch = secondPoint;

			const initialAngle = firstPoint.subtract(secondPoint);
			this.initialAngleChange = Math.atan2(initialAngle.y, initialAngle.x);

			this.initialDistance = calculateDistanceBetween2DPoints(firstPoint, secondPoint);

			if (!this.controlSceneManager.isEnabled) {
				this.ground.shouldDisablePhysicsBodyStep = false;
			}
		}
	}

	/**
	 * Handle touch move event.
	 * @param event Touch event.
	 */
	protected override onTouchmove(event: TouchEvent): void {
		if (event.touches.length === NUMBER_OF_TOUCHES) {
			const minDistanceDifferenceForScaling = 75;
			const minRotationForRotate = 0.35;

			const [firstPoint, secondPoint] = this.getVectorsFromTouchList(event);
			assertNonNull(this.firstInitialTouch);
			assertNonNull(this.secondInitialTouch);

			const distance = calculateDistanceBetween2DPoints(firstPoint, secondPoint);
			const difference = distance - this.initialDistance;
			if (Math.abs(difference) >= minDistanceDifferenceForScaling) {
				const scalingCoefficient = difference / 50;
				this.changeFieldDistanceToCamera(scalingCoefficient);
			}

			// Algorithm for calculating the angle between two fingers
			// See https://stackoverflow.com/a/32635740
			const finalAngle = firstPoint.subtract(secondPoint);
			const rotation = Math.atan2(finalAngle.y, finalAngle.x) - this.initialAngleChange;

			if (Math.abs(rotation) >= minRotationForRotate) {
				this.ground.instance.rotation.y += rotation / 25;
			}
		}
	}

	/**
	 * Handle touch end event.
	 * @param _event Touch event.
	 */
	protected override onTouchend(_event: TouchEvent): void {
		this.firstInitialTouch = null;
		this.secondInitialTouch = null;

		if (!this.controlSceneManager.isEnabled) {
			this.ground.shouldDisablePhysicsBodyStep = true;
		}
	}
}
